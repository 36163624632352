var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('Loader',{attrs:{"loading":_vm.loading}}),_c('Table-Container',{attrs:{"title":"Historial: Morosos","cardTextFooter":"","exportInfo":_vm.items},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('Basic-Btn',{attrs:{"text":"Actualizar","color":"success","icon":"mdi-refresh","loading":_vm.loading},on:{"click":function($event){return _vm.refresh()}}})]},proxy:true},{key:"search",fn:function(){return [_c('v-text-field',{staticClass:"mx-2",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","maxlength":"150"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"footer-props":{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] },"items-per-page":20,"item-key":"id","dense":""},scopedSlots:_vm._u([{key:"item.nombre_completo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno).trim())+" ")]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('$' + _vm.formatNumberToMoney(item.monto))+" ")]}},{key:"item.deuda",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('$' + _vm.formatNumberToMoney(item.deuda))+" ")]}},{key:"item.fraude",fn:function(ref){
var item = ref.item;
return [(!item.fraude)?_c('v-chip',{attrs:{"x-small":"","color":""}},[_vm._v("Sin fraude")]):_c('v-chip',{attrs:{"x-small":"","color":"error"}},[_vm._v("Fraude")])]}},{key:"no-data",fn:function(){return [_vm._v(" No hay información disponible ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" No se obtuvieron resultados ")]},proxy:true}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"secondary","indeterminate":""},slot:"progress"})],1)],1),_c('BasicAlert',{attrs:{"active":_vm.alert.active,"msg":_vm.alert.msg,"color":_vm.alert.color},on:{"update:active":function($event){return _vm.$set(_vm.alert, "active", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }